import React from "react";
import { CircularProgress } from "@material-ui/core";
import { ClientOnBoardingStatus } from "../../../codegen/gql/graphql";
import { PROCESSING_STATUS } from "../../../services/constants";

interface KYCSectionProps {
  isVisible: boolean;
  kycStatus: ClientOnBoardingStatus;
  kycInitiationStatus: string;
  onInitiateKyc: () => void;
}

const KYCSection: React.FC<KYCSectionProps> = ({ isVisible, kycStatus, kycInitiationStatus, onInitiateKyc }) => {
  if (!isVisible || (kycStatus !== ClientOnBoardingStatus.Error && kycStatus !== ClientOnBoardingStatus.Nonexistent)) {
    return null;
  }

  return (
    <div className="flex w-full flex-col lg:flex-row mb-2">
      <div className="w-[80px]">
        <p className="label-text">KYC missing</p>
      </div>
      <button className="w-fit text-white px-2 py-0 ml-4 flex items-center rounded-md bg-primary-blue gap-2" onClick={onInitiateKyc}>
        <p>Initiate KYC procedure</p>
        {kycInitiationStatus === PROCESSING_STATUS.PROCESSING && <CircularProgress size={12} color="inherit" />}
      </button>
    </div>
  );
};

export default KYCSection;
