import React from "react";
import AvatarTooltip from "../../AvatarTooltip/AvatarTooltip";
import { IProductWithBrokers } from "../../../types/client.types";

interface ProductSectionProps {
  products: IProductWithBrokers[];
  side: "BUY" | "SELL";
  title: string;
}

const ProductSection: React.FC<ProductSectionProps> = ({ products, side, title }) => {
  const filteredProducts = products
    .filter((value) => value.side === side)
    .sort((first, second) => {
      if (first?.product?.name && second?.product?.name) {
        return first.product.name.localeCompare(second.product.name);
      }
      return 0;
    });

  if (filteredProducts.length === 0) return null;

  return (
    <div>
      <h4 className="mb-4">{title}:</h4>
      <div className="flex flex-wrap gap-8">
        {filteredProducts.map((productWithBrokers, index) => (
          <div key={index} className="w-full p-4 border border-gray-300 rounded-lg">
            <div className="flex gap-2">
              <span className="block">{productWithBrokers.product.name}</span>
            </div>
            <div className="w-full my-2 border-b border-gray-300"></div>
            <div className="flex flex-wrap w-full gap-2 pt-2">
              {productWithBrokers.brokers.map((broker, brokerIndex) => (
                <div key={brokerIndex} className="w-7 h-7">
                  <AvatarTooltip img={broker?.userInfo?.imageUrl ?? ""} broker={broker.brokerName} hover={true} />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductSection;
