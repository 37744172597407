import React, { useState } from "react";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { PROCESSING_STATUS } from "../../../services/constants";
import useDeals from "../../../hooks/useDeals";
import { usePermissions } from "../../../typedHooks/usePermissions";
import useInitiateClientKyc from "../../../typedHooks/mutations/useInitiateClientKyc";
import AddBackdropModal from "../../Modals/AddBackdropModal";
import CreateOrUpdateClientForm from "../../CreateOrUpdateClientForm/CreateOrUpdateClientForm";
import { IDealsResponse, ISingleClientProps } from "../../../types/client.types";
import ClientInfoSection from "./ClientInfoSection";
import ProductSection from "./ProductSection";
import DealsSection from "./DealsSection";
import KYCSection from "./KYCSection";
import { ClientOnBoardingStatus } from "../../../codegen/gql/graphql";

const SingleClient: React.FC<ISingleClientProps> = ({ data, refetchClientsFunction }) => {
  const [showAddOrClientClientModal, setShowAddOrClientClientModal] = useState(false);
  const [kycInitiationStatus, setKycInitiationStatus] = useState(PROCESSING_STATUS.NOT_INITIATED);

  const { isAdmin, isEngineer } = usePermissions();
  const initiateKyc = useInitiateClientKyc();
  const { deals } = useDeals({ clientIds: [data.id ?? null], take: 6 });
  const typedDeals = deals as IDealsResponse | false;

  const handleCloseForm = () => {
    setShowAddOrClientClientModal(false);
  };

  const handleInitiateKyc = async () => {
    setKycInitiationStatus(PROCESSING_STATUS.PROCESSING);
    try {
      await initiateKyc({
        variables: {
          input: {
            clientId: parseInt(data.id, 10),
          },
        },
      });
      setKycInitiationStatus(PROCESSING_STATUS.SUCCESS);
      toast.success(`KYC procedure for ${data.companyName} has initiated.`);
      refetchClientsFunction();
    } catch (error) {
      setKycInitiationStatus(PROCESSING_STATUS.FAILED);
      toast.error(`Failed to initiate KYC procedure for ${data.companyName}.`);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: {
          delay: 0.2,
          duration: 0.1,
        },
      }}
      exit={{
        opacity: 0,
        transition: {
          duration: 0.1,
        },
      }}
      className="p-4 lg:p-8 bg-gray-100"
    >
      {data && (
        <>
          <ClientInfoSection data={data} onUpdateClick={() => setShowAddOrClientClientModal(true)} />

          <KYCSection
            isVisible={isAdmin || isEngineer}
            kycStatus={data.kycStatus ?? ClientOnBoardingStatus.Nonexistent}
            kycInitiationStatus={kycInitiationStatus}
            onInitiateKyc={handleInitiateKyc}
          />

          <div className="flex flex-wrap w-full gap-4">
            {data.productWithBrokers && (
              <div className="flex-1 p-4 bg-gray-100 rounded-lg">
                <h3 className="mb-4 text-xl">Products</h3>
                <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                  <ProductSection products={data.productWithBrokers} side="SELL" title="Sells" />
                  <ProductSection products={data.productWithBrokers} side="BUY" title="Buys" />
                </div>
              </div>
            )}

            {typedDeals && typedDeals.dealslist && <DealsSection deals={typedDeals.dealslist} />}
          </div>

          <AddBackdropModal open={showAddOrClientClientModal} handleClose={handleCloseForm} height="fit-content">
            <CreateOrUpdateClientForm initialValues={data as any} closeAfterSubmit={handleCloseForm} />
          </AddBackdropModal>
        </>
      )}
    </motion.div>
  );
};

export default SingleClient;
