import { ContactType, Maybe, Product } from "../../codegen/gql/graphql";
import helpers from "../../services/helpers";
import { ICompanyContact } from "../../services/utils/interfaces/ClientDisplayTypes";
import AvatarTooltip from "../AvatarTooltip/AvatarTooltip";
import { Link } from "react-router-dom";
import LastContactedBy from "./elements/LastContactedBy/LastContactedBy";

const ContactTableRow = ({
  row,
  isLast,
  hideCompanyName,
  contactTypeFilter,
}: {
  row: ICompanyContact;
  isLast: boolean;
  hideCompanyName: boolean;
  contactTypeFilter?: ContactType[];
}) => {
  const gridColumns = helpers.getCrmGridColumns(contactTypeFilter, hideCompanyName);

  return (
    <div className={` grid grid-cols-8 border-b border-gray-300 ${isLast ? "border-b-0" : ""} font-regular ${gridColumns}`}>
      {!hideCompanyName && (
        <Link
          style={{ fontFamily: "Gilroy Medium" }}
          className="cursor-pointer p-2 border-r border-gray-300 col-span-2 flex-wrap hover:bg-[#f3f3f3] hover:underline items-center flex"
          to={{
            pathname: `/crm/${row.company.id}`,
          }}
        >
          {row.company.companyName ?? "nan"}
        </Link>
      )}
      <div className="p-2 border-r border-gray-300 col-span-2 flex-wrap items-center flex">{row.name}</div>
      <div className="p-2 border-r border-gray-300 col-span-3 flex-wrap hidden md:flex items-center">
        <a className="underline text-primary-blue" href={`mailto:${row.email}`}>
          {row.email}
        </a>
      </div>
      <div className="p-2 border-r border-gray-300 col-span-2 flex-wrap items-center flex">
        <a href={`tel:${row.phone}`} className="text-primary-blue underline">
          {row.phone}
        </a>
      </div>
      {contactTypeFilter && contactTypeFilter.length > 0 && contactTypeFilter[0] === ContactType.Sales && (
        <>
          <div className="p-2 border-r border-gray-300 gap-2 col-span-2 flex-wrap hidden md:flex items-center">
            {row.products.map((product: Maybe<Product>) => (
              <div key={product?.id} className="text-xs border border-black rounded-full px-2 py-0.5 flex justify-center items-center">
                <span>{product?.name}</span>
              </div>
            ))}
          </div>
          <div className="p-2 border-r border-gray-300  gap-2 flex-wrap hidden md:flex items-center">
            {row.mainBroker && (
              <div className="min-w-[24px] w-[24px] min-h-[24px] h-[24px]">
                <AvatarTooltip broker={row.mainBroker.brokerName} img={row.mainBroker?.userInfo?.imageUrl} hover type="primary" />
              </div>
            )}
            {row.secondBroker && (
              <div className="min-w-[24px] w-[24px] min-h-[24px] h-[24px]">
                <AvatarTooltip broker={row.secondBroker.brokerName} img={row.secondBroker?.userInfo?.imageUrl} hover type="secondary" />
              </div>
            )}
            {row.thirdBroker && (
              <div className="min-w-[24px] w-[24px] min-h-[24px] h-[24px]">
                <AvatarTooltip broker={row.thirdBroker.brokerName} img={row.thirdBroker?.userInfo?.imageUrl} hover type="tertiary" />
              </div>
            )}
          </div>
        </>
      )}
      <div className=" flex gap-2 items-center col-span-2 ">
        <LastContactedBy row={row} />
      </div>
    </div>
  );
};

export default ContactTableRow;
