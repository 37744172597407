import React, { useMemo } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import SingleClient from "./SingleClient";
import TableExpanding from "./TableExpanding";
import useClientsFull from "../../hooks/useClientsFull";
import LaunchScreen from "../../components/LaunchScreen/LaunchScreen";
import { IClientData, IFormattedClient, IClientsData, IExpandableRow } from "../../types/client.types";

function ClientsTableExpanding(): JSX.Element {
  const filterData = (data: IFormattedClient[]): IFormattedClient[] => {
    return data.map((client) => ({
      ...client,
      firstDealDate: client.firstDealDate ? client.firstDealDate.substring(0, 10) : "",
    }));
  };

  const [clients, refetch] = useClientsFull() as [IClientsData | false, () => void];

  const columns = useMemo(
    () => [
      {
        Header: () => null,
        id: "expander",
        Cell: ({ row }: { row: IExpandableRow<IFormattedClient> }) => (
          <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}</span>
        ),
      },
      { Header: "Client name", accessor: "companyName" },
      { Header: "Broker Provided", accessor: "broker.userInfo.fullName" },
      { Header: "Onboarding status", accessor: "onBoardingStatus" },
      { Header: "KYC status", accessor: "kycStatus" },
    ],
    []
  );

  const renderRowSubComponent = React.useCallback(
    (data: IFormattedClient[], rowOriginal: IFormattedClient) => {
      const currentClient = data.find((client) => client.id === rowOriginal.id);
      if (!currentClient) return null;

      return <SingleClient data={(currentClient as unknown) as IClientData} refetchClientsFunction={refetch} />;
    },
    [refetch]
  );

  if (!clients) {
    return (
      <>
        <LaunchScreen />
        <div>Loading data. This might take up to 10 seconds.</div>
      </>
    );
  }

  return <TableExpanding renderRowSubComponent={renderRowSubComponent} data={clients.clients} columns={columns} filterData={filterData} />;
}

export default ClientsTableExpanding;
