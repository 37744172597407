import { format } from "date-fns";
import { ICompanyContact } from "../../../../services/utils/interfaces/ClientDisplayTypes";
import AvatarTooltip from "../../../AvatarTooltip/AvatarTooltip";
import { motion } from "framer-motion";
import AutorenewRoundedIcon from "@mui/icons-material/AutorenewRounded";
import { useState, useEffect } from "react";
import { useUser } from "../../../../typedHooks/useUser";

interface LastContactedByProps {
  row: ICompanyContact;
}

const LastContactedBy = ({ row }: LastContactedByProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const { localUser } = useUser();

  useEffect(() => {
    if (isUpdated) {
      const timer = setTimeout(() => {
        setIsUpdated(false);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [isUpdated]);

  const handleUpdate = () => {
    console.log("last contacted by", localUser?.id, " at ", new Date().toISOString());
    setIsUpdated(true);
    setIsHovered(false);
  };

  const swapTransition = { duration: 0.15, ease: "linear" };

  return (
    <motion.div
      className="relative col-span-2 rounded-lg w-full min-h-[56px]"
      initial={{ boxShadow: "none" }}
      animate={isUpdated ? { boxShadow: "0px 0px 10px 2px rgba(134, 206, 243, 0.7)" } : { boxShadow: "none" }}
      transition={{ duration: 0.3, ease: "easeOut" }}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
    >
      <motion.div
        className="absolute inset-0 flex items-center gap-2 p-2"
        initial={{ opacity: 1 }}
        animate={{
          opacity: isHovered ? 0 : 1,
        }}
        transition={swapTransition}
        style={{ pointerEvents: isHovered ? "none" : "auto" }}
      >
        <div className="min-w-[24px] w-[24px] min-h-[24px] h-[24px]">
          {row.lastContactedBy && <AvatarTooltip broker={row.lastContactedBy?.fullName} img={"default.png"} hover />}
        </div>
        <span className="">{row.lastContactedAt ? format(new Date(row.lastContactedAt), "dd MMM yyyy") : ""}</span>
      </motion.div>
      <motion.button
        className="absolute inset-0 flex items-center gap-2 bg-[#D8EDF4] rounded-[8px] border border-[#86CEF3] mx-2 my-auto"
        style={{
          width: "fit-content",
          height: "fit-content",
          padding: "0.55rem 1rem",
          pointerEvents: isHovered ? "auto" : "none",
        }}
        initial={{ opacity: 0 }}
        animate={{
          opacity: isHovered ? 1 : 0,
        }}
        transition={swapTransition}
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        onClick={handleUpdate}
      >
        <AutorenewRoundedIcon style={{ height: "20px", width: "20px" }} />
        <span>update</span>
      </motion.button>
    </motion.div>
  );
};

export default LastContactedBy;
