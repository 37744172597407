import React from "react";
import Moment from "react-moment";
import { IDeal } from "../../../types/client.types";
import helpers from "../../../services/helpers";

interface DealsSectionProps {
  deals: IDeal[];
}

const DealsSection: React.FC<DealsSectionProps> = ({ deals }) => {
  if (!deals || deals.length === 0) return null;

  return (
    <div className="w-full mb-4 rounded-lg lg:flex-1">
      <h3 className="text-xl mb-9">Deals</h3>
      <div className="flex flex-wrap gap-y-4 justify-between">
        {deals.slice(0, 6).map((deal: IDeal, index: number) => (
          <div key={index} className="relative bg-white w-full p-4 rounded-lg lg:mt-4 lg:grow lg:max-w-[calc(50%-16px)]">
            <div>
              <div className="flex justify-between align-center">
                <div className="text-lg text-primary-blue ">
                  <a className="cursor-pointer link-deal" href={`/mydeals/${deal.id ?? ""}`} target="_blank" rel="noreferrer">
                    {deal.olyxRef ?? ""}
                  </a>
                </div>
                <div className="mt-1 text-xs text-gray-500">
                  <Moment format="D MMM YYYY">{new Date(deal.dealDate)}</Moment>
                </div>
              </div>
              <div className="w-full mb-2 border-b border-gray-300"></div>
              <span className="pb-2">
                {deal.volume ? Number(deal.volume).toLocaleString("en-US") + " " : ""}
                {deal.unit
                  ? deal.unit.descriptionShort && deal.unit.descriptionShort !== "n/a"
                    ? deal.unit.descriptionShort + " "
                    : deal.unit.description && deal.unit.description !== "n/a"
                    ? deal.unit.description + " "
                    : " "
                  : " "}
              </span>
              <span className="pb-2">{deal.productSpec?.product?.name ?? ""}</span>
              <div>
                <span className="pb-2">{helpers.formatMoney(deal.pricePerUnit ?? "", deal.currencyDeal?.description ?? "")}</span>
              </div>
              <div>
                <span className="pb-2">
                  {deal.seller?.companyName ? helpers.shortenCompanyName(deal.seller.companyName) + " - " : ""}
                  {deal.buyer?.companyName ? helpers.shortenCompanyName(deal.buyer.companyName) : ""}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DealsSection;
