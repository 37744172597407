import React from "react";
import Moment from "react-moment";
import { motion } from "framer-motion";
import EditIcon from "@mui/icons-material/Edit";
import ExactSyncStatusDisplay from "../../ExactSyncStatusDisplay/ExactSyncStatusDisplay";
import { IClientData } from "../../../types/client.types";

interface ClientInfoSectionProps {
  data: IClientData;
  onUpdateClick: () => void;
}

const ClientInfoSection: React.FC<ClientInfoSectionProps> = ({ data, onUpdateClick }) => {
  const slashMotion = {
    rest: { scale: 1, ease: "easeOut", duration: 0.2, type: "tween" },
    hover: {
      scale: 1,
      rotate: 20,
      transition: {
        duration: 0.2,
        type: "tween",
        ease: "easeIn",
      },
    },
  };

  return (
    <div className="relative">
      <h3 className="text-xl">Client info</h3>
      <motion.button
        initial="rest"
        whileHover="hover"
        animate="rest"
        className="md:absolute top-0 right-0 rounded md:p-2 text-primary-blue underline underline-offset-4 decoration-1 opacity-90 hover:text-gray-dark text-sm ease-in-out duration-300"
        onClick={onUpdateClick}
      >
        Update client information
        <motion.span variants={slashMotion} className="transform">
          <EditIcon className="pb-2 hidden md:block" />
        </motion.span>
      </motion.button>
      <div className="w-full">
        <div>
          <div className="w-full mt-5 border-collapse mb-7 flex flex-wrap">
            <InfoRow label="Name" value={data.companyName} />
            <InfoRow label="Client Group" value={data.clientGroup.clientGroupName} />
            <InfoRow label="Country" value={data.country.name} />
            <InfoRow label="First deal" value={<Moment format="D MMM YYYY">{new Date(data.firstDealDate)}</Moment>} />
            <div className="flex w-full flex-col lg:flex-row mb-2">
              <p className="label-text">Exact status</p>
              <div className="ml-2">
                <ExactSyncStatusDisplay idExact={data.idExact} companyId={data.id} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface InfoRowProps {
  label: string;
  value: React.ReactNode;
}

const InfoRow: React.FC<InfoRowProps> = ({ label, value }) => (
  <div className="flex w-full flex-col lg:flex-row mb-2">
    <div className="w-[80px]">
      <p className="label-text">{label}</p>
    </div>
    <div className="w-full lg:w-[calc(100%-88px)] flex items-center">
      <p className="lg:ml-4">{value}</p>
    </div>
  </div>
);

export default ClientInfoSection;
